import React from "react"
import BackgroundImage from "gatsby-background-image"
import "./hero.scss"
import { useStaticQuery, graphql } from "gatsby"

const Hero = props => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="div"
      className="hero"
      fluid={data.background.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <div className="container">
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1>{props.title}</h1>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
